<template>
  <div class="main">
    <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/warning/error.png" alt="">
    <div class="text">哎呀！系统出错了</div>
  </div>
</template>

<script>
  // 系统错误
  export default {
    name: 'error',
  }
</script>

<style scoped lang="scss">
  .main{
    height: calc(100vh - 360px);
    min-height: 512px;
    width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;

    img{
      width: 100px;
      height: 82px;
      margin-bottom: 35px;
    }

    .text{
      font-size: 15px;
      color: #999999;
      font-weight: 500;
    }
  }
</style>
